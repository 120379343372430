@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
body {
    margin: 0;
    font-family: 'Montserrat', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow: inherit !important;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.main-head {
    height: 150px;
    background: #FFF;
}

.sidenav {
    height: 100%;
    background-color: #000;
    overflow-x: hidden;
    padding-top: 20px;
}

.main {
    padding: 0px 10px;
}

@media screen and (max-height: 450px) {
    .sidenav {
        padding-top: 15px;
    }
}

@media screen and (max-width: 450px) {
    .login-form {
        margin-top: 10%;
    }
    .register-form {
        margin-top: 10%;
    }
}

@media screen and (min-width: 768px) {
    .main {
        margin-left: 40%;
    }
    .sidenav {
        width: 40%;
        position: fixed;
        z-index: 1;
        top: 0;
        left: 0;
    }
    .login-form {
        margin-top: 80%;
    }
    .register-form {
        margin-top: 20%;
    }
}

.login-main-text {
    margin-top: 20%;
    padding: 60px;
    color: #fff;
}

.login-main-text h2 {
    font-weight: 300;
}

.btn-black {
    background-color: #000 !important;
    color: #fff !important;
}

.cancel-color {
    position: absolute;
    top: 0;
    right: 0;
    padding: 10px;
    height: 100%;
}

.cursor-pointer:hover {
    cursor: pointer;
}

.text-bold {
    font-weight: bold;
}

.ck-editor__editable {
    min-height: 300px;
}

.deleteImage {
    position: absolute;
    top: -10px;
    left: -5px;
    height: 20px;
    width: 20px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loader-container {
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    overflow: hidden;
    z-index: 99999;
    display: flex;
    justify-content: center;
    align-items: center;
}

.balls {
    max-height: 100vh;
    height: 100vh;
    display: flex;
}

.org-Ball {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    position: relative;
    width: 25px;
    height: 25px;
    border-radius: 100px;
    background: #fff;
    margin: 0;
    animation: org .5s linear both;
    animation-delay: .5s;
}

.Ball-one,
.Ball-two,
.Ball-three,
.Ball-for,
.Ball-fiv,
.Ball-six {
    position: absolute;
    width: 25px;
    height: 25px;
    border-radius: 100px;
    background: cornsilk;
    margin: 0;
    filter: blur(1px) contrast(12);
}

.Ball-one {
    animation: top 4s linear both infinite;
}

.Ball-two {
    animation: bottom 4s linear both infinite;
}

.Ball-three {
    animation: left 4s linear both infinite;
    animation-delay: 2s;
}

.Ball-for {
    animation: right 4s linear both infinite;
    animation-delay: 2s;
}

@keyframes top {
    0% {
        top: 0;
    }
    25% {
        top: 0;
    }
    50% {
        top: -6em;
    }
    75% {
        top: 0;
    }
    100% {
        top: 0;
    }
}

@keyframes bottom {
    0% {
        top: 0;
    }
    25% {
        top: 0;
    }
    50% {
        top: 6em;
    }
    75% {
        top: 0;
    }
    100% {
        top: 0;
    }
}

@keyframes left {
    0% {
        left: 0;
    }
    25% {
        left: 0;
    }
    50% {
        left: 6em;
    }
    75% {
        left: 0;
    }
    100% {
        left: 0;
    }
}

@keyframes right {
    0% {
        left: 0;
    }
    25% {
        left: 0;
    }
    50% {
        left: -6em;
    }
    75% {
        left: 0;
    }
    100% {
        left: 0;
    }
}

.border-radius {
    border-radius: 20px !important;
}

.text-medium {
    font-weight: 600 !important;
}

.cursor-pointer {
    cursor: pointer !important;
}

.card-header:hover {
    cursor: pointer;
}

.card-header {
    background-color: var(--white) !important;
    border: none !important;
}

.custom-file-label {
    z-index: 0 !important;
}

.ant-checkbox-wrapper {
    margin: 10px 5px !important;
}

.ant-checkbox-wrapper span {
    font-size: 16px;
}
@keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .loading-spinner {
    width: 20px;
    height: 20px;
    border: 2px solid #f3f3f3; /* Light grey */
    /* border-top: 10px solid #383636; Black */
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
  }
  .logos{
      height: 150px;
      width: 250px;
      border-radius: 30%;

  }
  .adjust-header{
    display: flex !important ;
    justify-content: end !important;
  }
  .position-box{
    width: 50px;
    border-radius: 4px;
  }